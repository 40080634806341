import { CssBaseline, Grid, Typography } from '@mui/material';
import React from 'react';
import { ErrorMessage } from './components/Common/ErrorMessage';
import { ImagePreview } from './components/Settings/ImagePreview';
import { RenderingLoader } from './components/Settings/RenderingLoader';
import { SidePanelLeft } from './components/SidePanelLeft';
function App() {
  return (
    <>
      <CssBaseline />
      <Grid
        container
        padding={ 5 }
        rowSpacing={ { sx: 1 } }
        columnSpacing={ 10 }
        sx={ { height: '100vh' } }
      >
        <Grid item xs={ 12 } marginBottom={ 5 } >
          <Typography variant='h1'  sx={ { color: '#444', textAlign: 'center' } }>
            ideochrom
          </Typography>
          <Typography variant='h2'  sx={ { color: '#333', textAlign: 'center' } }>
           a web app that allows chromosome ideogram generation
            </Typography>
          <Typography variant='body1' component='div' sx={ { color: '#333', textAlign: 'center' } }>
            Upload your choice of cytoband, genes, and sample files to create ideograms.
          </Typography>
        </Grid>
        <Grid item xs={ 12 } md={ 4 }>
          <SidePanelLeft />
        </Grid>
        <Grid item xs={ 12 } md={ 8 } >
          <ImagePreview />
        </Grid>

      </Grid >
      <RenderingLoader />
      <ErrorMessage />
    </ >
  );
}

export default App;
