import {ClearOutlined} from '@mui/icons-material';
import {Button, Chip, IconButton, Popper, Stack} from '@mui/material';

import React from 'react';
import {ChromePicker} from 'react-color';
import {SettingsModel} from '@models';
import {ColorBox} from './ColorBox';


export interface ColorBoxProps {
    colorModel: SettingsModel;
    saveColor: (colorModel: SettingsModel) => void;
}


export const ColorBoxEdit = ({colorModel, saveColor}: ColorBoxProps) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event: any) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleColorClick = (colorHex: string) => {
        let currentModel = {...colorModel, value: colorHex};
        saveColor(currentModel);
    };
    const open = Boolean(anchorEl);
    return <>
        <Stack direction='row'>

            <Button fullWidth
                onClick={handleClick}
                title='Click to set new color override'
            >
                <ColorBox colorModel={colorModel} />
            </Button >
            <IconButton aria-label="delete" onClick={() => handleColorClick('')} title='Reset to original color' >
                <ClearOutlined />
            </IconButton>
        </Stack>
        <Popper
            open={open}
            anchorEl={anchorEl}

        >
            <Stack direction='column' spacing={2} alignContent='center' sx={{backgroundColor: 'white', border: "1px solid black", padding: 2}}>

                <ChromePicker
                    color={colorModel.value}
                    onChange={(result) => handleColorClick(result.hex)}
                />
                <Chip clickable onClick={() => setAnchorEl(null)} label="Close" variant='outlined' />
            </Stack>
        </Popper>
    </>;
};