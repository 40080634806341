
import React from "react";
import { RootState, useDispatch, useSelector } from "src/store";
import { setGenesFile } from '../../reducers/BuildRenderRequestSlice';
import { FileUpload } from "../Common/FileUpload";

export const GenesFileUpload = () => {
    const dispatch = useDispatch();
    const { genesFile } = useSelector( ( state: RootState ) => state.buildRenderRequest );
    return <FileUpload label={ "Genes File (optional)" } file={ genesFile } onFileUpdate={ ( file ) => dispatch( setGenesFile( file ) ) } />
};