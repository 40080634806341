import {Box, Stack, Typography} from "@mui/material";
import React from "react";
import {SettingsModel} from "@models";

export interface ColorBoxProps {
    colorModel: SettingsModel;
}
export const ColorBox = ({colorModel}: ColorBoxProps) => {

    const isSet = Boolean(colorModel?.value);
    return <Stack direction="row" spacing={1}>
        {isSet ? <>
            <Box sx={{
                width: 20,
                height: 20,
                backgroundColor: colorModel.value,
                border: "1px solid black"
            }} />
            <Typography variant='caption'>{colorModel.value}</Typography>
        </>
            : <i>Not Set</i>}
    </Stack>
};
