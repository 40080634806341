import CircularProgress from "@mui/material/CircularProgress";

import React from "react";
import { RootState, useDispatch, useSelector } from "src/store";
import { setAbnormalColorOverrides } from '../../reducers/BuildRenderRequestSlice';
import { useGetSettingsQuery } from '../../services/chromosomeApi';

import { ColorSelect } from "../Common/ColorSelect";

export const AbnormalityStains = () => {
    const dispatch = useDispatch();
    const { isLoading, data } = useGetSettingsQuery();
    const { abnormalColorOverrides } = useSelector( ( state: RootState ) => state.buildRenderRequest );
    return isLoading ? <CircularProgress /> :
        <ColorSelect
            selectedColors={ abnormalColorOverrides }
            colors={ data?.abnormalityColors || [] }
            colorChanges={ ( colors ) => dispatch( setAbnormalColorOverrides( colors ) ) } />


}