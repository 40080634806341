
import React from "react";
import { RootState, useDispatch, useSelector } from "src/store";
import { setCytoBandFile } from '../../reducers/BuildRenderRequestSlice';
import { FileUpload } from "../Common/FileUpload";

export const BandsFileUpload = () => {
    const dispatch = useDispatch();
    const { cytoBandFile } = useSelector( ( state: RootState ) => state.buildRenderRequest );
    return <FileUpload label={ "Cytobands File" } file={ cytoBandFile } onFileUpdate={ ( file ) => dispatch( setCytoBandFile( file ) ) } />
};