import { InfoRounded } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import React from "react";
import { RootState, useDispatch, useSelector } from "src/store";
import { setHideUnusedAbnormalityTypes } from '../../reducers/BuildRenderRequestSlice';

export const HideUnusedAbnormalityTypesCheckBox = () => {
    const dispatch = useDispatch();
    const { hideUnusedAbnormalityTypes } = useSelector( ( state: RootState ) => state.buildRenderRequest );
    return <FormControlLabel
        checked={ hideUnusedAbnormalityTypes }
        control={ <Checkbox /> }
        onChange={ ( _, checked ) => dispatch( setHideUnusedAbnormalityTypes( checked ) ) }
        label={ <>
            <b>Show Only Visible Abnormality Types in Legend</b>
            <Tooltip describeChild title="Render only the filtered abnormality types in the legend.">
                <InfoRounded color="info" />
            </Tooltip>
        </> } />

};