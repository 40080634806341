import {EmailTwoTone, InfoTwoTone} from "@mui/icons-material";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React, {useCallback, useState} from "react";

export const ContactMe = () => {
    const [open, setOpen] = useState<boolean>(false);
    const close = useCallback(() => {
        setOpen(false);
    }, [setOpen]);
    return <>
        <Button variant="outlined" color="info" onClick={() => setOpen(true)}>
            <EmailTwoTone color='disabled' /> Contact Me
        </Button >
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Subscribe</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <InfoTwoTone color='info' /> Have questions? Issues?  Feel fee to contact me!
                </DialogContentText>
                <a href="mailto:admin@ideochrom.com">admin@ideochrom.com</a>
                {/*<TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Subject"
                    fullWidth
                    variant="standard"
                />*/}
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Close</Button>
                {/*<Button onClick={ close }>Send</Button>*/}
            </DialogActions>
        </Dialog>
    </>
};