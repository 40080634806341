import { Card, CardHeader, CardMedia, Skeleton } from "@mui/material";
import { RootState, useSelector } from "src/store";

import React from "react";

export const ImagePreview = () => {
    const { renderState, fileBlob } = useSelector( ( state: RootState ) => state.renderRequest );
    const urlCreator = window.URL || window.webkitURL;

    return <Card elevation={3}  >
<CardHeader>sdfsdfgsf</CardHeader>

        { renderState === 'complete' ?
            <CardMedia
                style={ { msContentZoomLimit: 'scale()', maxHeight: "100%", maxWidth:"100%"} }
                component={(fileBlob?.type ?? '').indexOf("pdf") >=0 ? "iframe" :"img"}
                image={ fileBlob && `${urlCreator.createObjectURL( fileBlob )}#zoom=60` }
            /> :
            <Skeleton
                variant="rectangular"
                style={ { display: "flex", flexDirection: "column" } }

                animation={ renderState === 'running' ? 'wave' : false }
                sx={ { height: '100VH', overflowY: 'auto' } }
            />
        }
    </Card>;

};