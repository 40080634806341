import { Alert, AlertTitle, Modal } from "@mui/material";
import React from "react";
import { RootState, useDispatch, useSelector } from "src/store";
import { setError } from '../../reducers/RenderRequestSlice';
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,

    boxShadow: 24,
    p: 4,

};
export const ErrorMessage = () => {
    const dispatch = useDispatch();
    const { error } = useSelector( ( state: RootState ) => state.renderRequest );

    return <Modal
        onClose={ () => dispatch( setError( null ) ) }
        open={ Boolean( error ) }
    ><Alert severity="error"
        sx={ style }
    >
            <AlertTitle >Error</AlertTitle>
            { error }
        </Alert>
    </Modal>;
};