import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import React from "react";
import { RootState, useDispatch, useSelector } from "src/store";
import knownHumanChromosomes from '../../constants/HumanChromosomeUtil';

import { setExcludedChromosomes } from '../../reducers/BuildRenderRequestSlice';

export const ChromosomeFilter = () => {
    const dispatch = useDispatch();
    const { excludedChromosomes } = useSelector( ( state: RootState ) => state.buildRenderRequest );

    const onChromosomeChange = ( addToExcluded: boolean, chromosome: string ) => {
        let newChromosomes = excludedChromosomes.filter( c => c !== chromosome );
        if ( addToExcluded ) {
            newChromosomes = [...excludedChromosomes, chromosome];
        }
        dispatch( setExcludedChromosomes( newChromosomes ) );
    };

    const toggleSelectAll = ( checkedSelectAll: boolean ) => {
        dispatch( setExcludedChromosomes( checkedSelectAll ? [] : knownHumanChromosomes ) );
    };

    return <Grid container
        spacing={ 2 }
        columns={ { xs: 4, sm: 8, md: 12 } }
    >
        { knownHumanChromosomes.map( ( c, index ) =>

            <Grid item >
                <FormControlLabel

                    label={ `Chromosome ${c}` }
                    control={ <Checkbox
                        onChange={ ( _, checked ) => onChromosomeChange( !checked, c ) }
                        checked={ !excludedChromosomes.some( e => e === c ) }
                    /> } />
            </Grid>
        ) }
        <Grid item>
            <FormControlLabel

                label={ `Select All` }
                control={ <Checkbox
                    onChange={ ( _, checked ) => toggleSelectAll( checked ) }
                    checked={ excludedChromosomes.length === 0 }
                /> } />
        </Grid>
    </Grid>;
};