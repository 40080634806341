import { MonetizationOnTwoTone } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React from "react";

export const Donate = () => {

    return <Box alignItems="center"    >

        <form action="https://www.paypal.com/donate" method="post" target="_top">
            <input type="hidden" name="business" value="GL7VZ4W9KEAXL" />
            <input type="hidden" name="no_recurring" value="0" />
            <input type="hidden" name="item_name" value="Help keep this site going by donating!  Every bit counts!  Thank you!" />
            <input type="hidden" name="currency_code" value="USD" />
            <Button type="submit" variant="contained" startIcon={ <MonetizationOnTwoTone /> } color="success">Donate!</Button>

        </form>
    </Box>;


};