import { Grid } from "@mui/material";
import React from "react";
import { ContactMe } from "../Common/ContactMe";
import { Donate } from "../Common/Donate";

export const TopInfo = () => {

    return <Grid container alignContent={ "space-evenly" }>
        <Grid item xs={ 12 } md={ 6 }>
            <ContactMe />
        </Grid>

        <Grid item xs={ 12 } md={ 6 } sx={ { textAlign: 'right' } } >
            <Donate />
        </Grid>
    </Grid>;
}